import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';
import useLocales from 'hooks/useLocales';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'storage';
import { RoutingServiceDashboard } from 'services/routing/RoutingService';
import useInitializePage from 'hooks/useInitializePage';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import { PAGE_TYPE } from 'services/routing/PATH_PARAM';
import useRecommendationTabs from 'pages/dashboard/recommendation/useRecommendationTabs';
import RecommendationsRulesListBlock from 'components/_dashboardPagesFeatures/recommendationsConfig/rules/RecommendationsRulesListBlock';
import EmptyListBlock from 'components/features/list/EmptyListBlock';
import { BubbleChartIcon } from 'components/ui/icons';
import RecommendationRuleListFilter from 'components/_dashboardPagesFeatures/recommendationsConfig/rules/RecommendationRuleListFilter';
import StringUtils from 'utils/String';
import { RecommendationConfigResponseDTO } from 'typings/dto/recommendationsConfig';

const rootModelPath: keyof RecommendationConfigResponseDTO = 'recommendationsConfig';
const modelPath: keyof RecommendationConfigResponseDTO['recommendationsConfig'] = 'recommendations';

export default function RulesListPage() {
  const { permissions } = useSelector((state) => state.auth);

  const { recommendationsConfigIsLoading, recommendationsConfig } = useSelector((state) => state.recommendationsConfig);
  const { translate } = useLocales();
  const navigate = useNavigate();
  const tabs = useRecommendationTabs();
  const { areAllLoading: areDictionariesLoading, dictionaryAll } = useSelector((state) => state.dictionary);
  const { areAllLoading: areServicesLoading, serviceList: services } = useSelector((state) => state.service);
  const [rules, setRules] = useState(recommendationsConfig?.rules || []);
  const [initialRules, setInitialRules] = useState(recommendationsConfig?.rules || []);

  const dictionaryMap: Record<string, Dictionary> = useMemo(() => {
    return dictionaryAll.reduce((acc: Record<string, Dictionary>, item: Dictionary) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [areDictionariesLoading]);

  const intersectRulesWithServices = () => {
    if (recommendationsConfig) {
      const rules: RecommendationRule[] = [...recommendationsConfig.rules];
      services.forEach((service) => {
        if (!recommendationsConfig.rules.some((rule) => rule.serviceItem.id === service.id)) {
          const rule = {
            id: StringUtils.generateUUIDv4(),
            serviceItem: { id: service.id, name: service.name },
            serviceCategory: { id: service.category.id, name: service.category.name },
            predefined: [],
          };
          rules.push(rule);
        }
      });
      setRules(rules);
      setInitialRules(rules);
    }
  };

  const isPageInitialized = useInitializePage(() => {
    intersectRulesWithServices();
    const title = translate('pages.recommendationsList.title');
    const breadcrumbs = RoutingServiceDashboard.recommendationsConfig.getListBc();
    const menu: MenuIconItemData[] = [];

    if (permissions.can(MODEL_PERMISSION.CAN_UPDATE_RECOMMENDATIONS)) {
      menu.push({
        title: translate('pages.promotionsAdd.title'),
        handler: () => navigate(RoutingServiceDashboard.recommendationsConfig.getAddPath()),
        icon: 'add',
      });
    }
    return { menu, title, breadcrumbs, tabs, currentTab: PAGE_TYPE.list };
  }, [recommendationsConfigIsLoading, areDictionariesLoading, areServicesLoading]);

  if (!isPageInitialized) {
    return <LoaderComponent />;
  }

  if (!recommendationsConfig) {
    return null;
  }

  if (services.length === 0) {
    return (
      <EmptyListBlock
        icon={<BubbleChartIcon />}
        title={translate('pages.recommendationsList.emptyTitle')}
        subtitle={translate('pages.recommendationsList.emptySubtitle')}
      ></EmptyListBlock>
    );
  }

  return (
    <>
      <RecommendationRuleListFilter
        isPageInitialized={isPageInitialized}
        serviceList={services}
        setRulesList={setRules}
        initialRules={initialRules}
      />
      <RecommendationsRulesListBlock
        recommendationRules={rules}
        dictionaryMap={dictionaryMap}
        servicesList={services}
        recommendationsConfig={recommendationsConfig}
        parentModelPath={rootModelPath + '.' + modelPath}
      />
    </>
  );
}

//export default React.memo(RulesListPage, () => true);
