import { CallResponseDTO, CreateCallRequestDTO } from 'typings/dto/call';
import ModelActionsService from '../ModelActionsService';
import ICallActionService from './ICallActionService';
import NavigateFrontendUtils from 'utils/NavigateFrontend';

export default class CallActionServiceImpl
  extends ModelActionsService<Call, CreateCallRequestDTO, CallResponseDTO>
  implements ICallActionService
{
  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt', NavigateFrontendUtils.SORT_ORDER.desc);
  };
}
