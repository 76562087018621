import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { FileIcon, PlusIcon } from 'components/ui/icons';
import useProviderTypeFilterOptions from 'components/_dashboardPagesFeatures/order/filters/serviceProvider/useProviderTypeFilterOptions';
import useTechnicianAssignStatusFilterOptions from 'components/_dashboardPagesFeatures/order/filters/serviceProvider/useTechnicianAssignStatusFilterOptions';
import DIContainer from 'services/DIContainer';
import ILanguageService from 'services/language/ILanguageService';
import { TIME_ZONE } from 'typings/common/date.enum';
import { DateIntervalDTO } from 'typings/dto/subEntity/dateInterval';
import {
  ORDER_COMPLETION_STATE,
  ORDER_COMPLETION_STATE_NOT_OVERDUE,
  ORDER_OPERATOR_TYPE,
  ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM,
  ORDER_SERVICE_PROVIDER_TYPE,
  ORDER_TECHNICIANS_ASSIGN_STATUS,
  ORDER_VISIT_DATE_COMPLETION_STATE,
} from 'typings/models/order/order.enum';
import {
  ORDER_FILTER,
  ORDER_FILTER_CUSTOM_FIELDS,
  ORDER_FILTER_DATE,
  ORDER_FILTER_SPECIAL_VALUE,
} from 'typings/models/order/orderFilter.enum';
import { DEFAULT_ORDER_VIEW_NAME } from 'typings/models/order/orderView.enum';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import DateUtils from 'utils/Date';
import FunctionUtils from 'utils/Function';
import SetCustom from 'utils/implementations/SetCustom';
import IMapper from 'utils/mappers/IMapper';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import OrderUtils from './OrderUtils';

export default class OrderViewUtils {
  /** Служебные поля на бекенде с применённой таймзоной имеют такую приписку */
  private static APPLIED_TIMEZONE_FIELD_NAME = 'AppliedTimezone';
  private static orderFilterSpecialValues = Object.values(ORDER_FILTER_SPECIAL_VALUE);
  private static iconMap: Record<string, React.FC> = {
    [DEFAULT_ORDER_VIEW_NAME.all]: FileIcon,
    [DEFAULT_ORDER_VIEW_NAME.actionRequired]: WarningRoundedIcon,
    [DEFAULT_ORDER_VIEW_NAME.proposedOrders]: PlusIcon,
  };

  public static mapViewToTab = (view: OrderView, translate: ILanguageService.TranslateFN): OrderTab => {
    let title = view.name;
    let preIcon: React.FC | undefined;

    if (view.type === 'DEFAULT') {
      preIcon = this.iconMap[view.technicalName] || null;
      title = translate(`pages.orders.views.${view.technicalName}`);
    }

    return { title, value: view.technicalName, pre: preIcon };
  };

  // Front to back filter special values handlers -----------------------------------------------------------------------------------

  /**
   * Обрабатывает специальные значения в фильтре вкладок перед отправкой на бек, которые нельзя передавать напрямую.
   * Мутирует переданный объект!
   */
  public static mapFilterSpecialTokens = (
    locationSearchObject: LocationSearchObject,
    utils: DIContainer.StatefulUtils,
    currentUser: User | null,
    customFields: CustomField[]
  ): LocationSearchObject => {
    if (ORDER_FILTER.creationDateType in locationSearchObject) {
      this.handleCreationDateFilter(locationSearchObject);
    }
    if (ORDER_FILTER.deliveryDateType in locationSearchObject) {
      this.handleDeliveryDateFilter(locationSearchObject, utils.date);
    }
    if (ORDER_FILTER.installationDateType in locationSearchObject || ORDER_FILTER.visitCompletionState in locationSearchObject) {
      this.handleInstallationDateFilter(locationSearchObject, utils.date);
    }
    if (ORDER_FILTER.completionState in locationSearchObject) {
      this.handleStatusFilter(locationSearchObject);
    }
    if (ORDER_FILTER.providerType in locationSearchObject) {
      this.handleProviderTypeFilter(locationSearchObject);
    }
    if (ORDER_FILTER.technicianAssignStatus in locationSearchObject) {
      this.handleTechnicianAssignStatusFilter(locationSearchObject);
    }
    if (ORDER_FILTER.label in locationSearchObject) {
      this.handleLabelFilter(locationSearchObject);
    }
    if (currentUser?.type === TENANT_TYPE.enterprise || currentUser?.type === TENANT_TYPE.platformOperator) {
      this.handleCustomFieldsFilter(locationSearchObject, customFields);
    }
    this.handleSpecialValues(locationSearchObject);
    return locationSearchObject;
  };

  /** Мутирует переданный объект! */
  // Для кастомных полей на бек отправляется пара запросов technicalName и tenant.id. Создается тут
  private static handleCustomFieldsFilter = (locationSearchObject: LocationSearchObject, customFields: CustomField[]) => {
    customFields.forEach((config) => {
      const param = locationSearchObject[config.id];
      if (param) {
        delete locationSearchObject[config.id];
        locationSearchObject[OrderUtils.getCustomFieldBackendFilterName(config)] = param;
        locationSearchObject[OrderUtils.getCustomFieldTenantIdBackendFilterName(config)] = NavigateFrontendUtils.createLocationSearchParam(
          config.tenant.id
        );
      }
    });
  };

  /** Мутирует переданный объект! */
  private static handleStatusFilter = (locationSearchObject: LocationSearchObject) => {
    switch (locationSearchObject[ORDER_FILTER.completionState]?.value) {
      case ORDER_COMPLETION_STATE_NOT_OVERDUE: {
        locationSearchObject[ORDER_FILTER.completionState] = NavigateFrontendUtils.createLocationSearchParam(
          [ORDER_COMPLETION_STATE.bad, ORDER_COMPLETION_STATE.warn],
          'ni'
        );
        break;
      }
    }
  };

  /** Мутирует переданный объект! */
  private static handleLabelFilter = (locationSearchObject: LocationSearchObject) => {
    delete locationSearchObject[ORDER_FILTER.labelMatchTypeExcluded];
  };

  /** Мутирует переданный объект! */
  private static handleCreationDateFilter = (locationSearchObject: LocationSearchObject) => {
    switch (locationSearchObject[ORDER_FILTER.creationDateType]?.value as ORDER_FILTER_DATE) {
      case ORDER_FILTER_DATE.afterCurrent: {
        locationSearchObject[ORDER_FILTER.creationDate] = NavigateFrontendUtils.createLocationSearchParam(new Date().toISOString(), 'gt');
        break;
      }
      case ORDER_FILTER_DATE.beforeCurrent: {
        locationSearchObject[ORDER_FILTER.creationDate] = NavigateFrontendUtils.createLocationSearchParam(new Date().toISOString(), 'lt');
        break;
      }
    }
    delete locationSearchObject[ORDER_FILTER.creationDateType];
    // TODO тут возможно надо будет менять таймзону
  };

  /** Мутирует переданный объект! */
  private static handleDeliveryDateFilter = (locationSearchObject: LocationSearchObject, dateUtils: DateUtils) => {
    const fromIntervalFieldName: keyof DateIntervalDTO = 'from';
    const toIntervalFieldName: keyof DateIntervalDTO = 'to';
    const fromParamName = ORDER_FILTER.deliveryDate + '.' + fromIntervalFieldName;
    const toParamName = ORDER_FILTER.deliveryDate + '.' + toIntervalFieldName;

    // Идёт поиск по специальному полю на беке, в котором дата стоит в зоне заказа, но при этом помечена нулевой зоной, поэтому здесь я должен сделать то же самое
    switch (locationSearchObject[ORDER_FILTER.deliveryDateType]?.value as ORDER_FILTER_DATE) {
      case ORDER_FILTER_DATE.afterCurrent: {
        const dateZoned = dateUtils.changeTimezone(new Date(), 'Zulu' as TIME_ZONE);
        locationSearchObject[fromParamName] = NavigateFrontendUtils.createLocationSearchParam(dateZoned.toISOString(), 'gt');
        break;
      }
      case ORDER_FILTER_DATE.beforeCurrent: {
        const dateZoned = dateUtils.changeTimezone(new Date(), 'Zulu' as TIME_ZONE);
        locationSearchObject[toParamName] = NavigateFrontendUtils.createLocationSearchParam(dateZoned.toISOString(), 'lt');
        break;
      }
      case ORDER_FILTER_DATE.between: {
        const datesParam = locationSearchObject[ORDER_FILTER.deliveryDate];
        if (!datesParam) {
          break;
        }
        const [dateFrom, dateTo] = datesParam.values;
        const dateFromZoned = dateUtils.changeTimezone(new Date(dateFrom), 'Zulu' as TIME_ZONE).toISOString();
        const dateToZoned = dateUtils.changeTimezone(new Date(dateTo), 'Zulu' as TIME_ZONE).toISOString();
        locationSearchObject[fromParamName] = NavigateFrontendUtils.createLocationSearchParam([dateFromZoned, dateToZoned], 'be');
        locationSearchObject[toParamName] = NavigateFrontendUtils.createLocationSearchParam([dateFromZoned, dateToZoned], 'be');
        break;
      }
      case ORDER_FILTER_DATE.exist: {
        locationSearchObject[ORDER_FILTER.deliveryDate] = NavigateFrontendUtils.createLocationSearchParam([ORDER_FILTER_DATE.exist], 'nn');
        break;
      }
      case ORDER_FILTER_DATE.notExist: {
        locationSearchObject[ORDER_FILTER.deliveryDate] = NavigateFrontendUtils.createLocationSearchParam(
          [ORDER_FILTER_DATE.notExist],
          'nl'
        );
        break;
      }
    }

    if (
      locationSearchObject[ORDER_FILTER.deliveryDateType]?.value !== ORDER_FILTER_DATE.exist &&
      locationSearchObject[ORDER_FILTER.deliveryDateType]?.value !== ORDER_FILTER_DATE.notExist
    ) {
      delete locationSearchObject[ORDER_FILTER.deliveryDate];
    }

    delete locationSearchObject[ORDER_FILTER.deliveryDateType];
  };

  /** Мутирует переданный объект! */
  private static handleProviderTypeFilter = (locationSearchObject: LocationSearchObject) => {
    type ProviderTypeValue = ReturnType<typeof useProviderTypeFilterOptions>[0]['id'];
    const providerTypeValue: ProviderTypeValue = locationSearchObject[ORDER_FILTER.providerType]?.value as ProviderTypeValue;
    switch (providerTypeValue) {
      case ORDER_FILTER_SPECIAL_VALUE.notExist: {
        locationSearchObject['assignInfo.providerType'] = NavigateFrontendUtils.createLocationSearchParam(ORDER_SERVICE_PROVIDER_TYPE.none);
        locationSearchObject['assignInfo.assignAlgorithmType'] = NavigateFrontendUtils.createLocationSearchParam(
          ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.none
        );
        break;
      }
      // case ORDER_SERVICE_PROVIDER_ASSIGN_TYPE.autoInProgress: {
      //   locationSearchObject['assignInfo.assignMethodType'] = NavigateFrontendUtils.createLocationSearchParam(
      //     ORDER_SERVICE_PROVIDER_ASSIGN_TYPE.autoInProgress
      //   );
      //   break;
      // }
      case ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.internalTechnicianConcurrent: {
        const values = [
          ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.internalTechnicianConcurrent,
          ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.internalTeamConcurrent,
        ];
        locationSearchObject['assignInfo.assignAlgorithmType'] = NavigateFrontendUtils.createLocationSearchParam(values, 'in');
        break;
      }
      case ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.spConcurrent: {
        locationSearchObject['assignInfo.assignAlgorithmType'] = NavigateFrontendUtils.createLocationSearchParam(
          ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.spConcurrent
        );
        break;
      }
      case ORDER_FILTER_SPECIAL_VALUE.exist: {
        locationSearchObject['assignInfo.providerType'] = NavigateFrontendUtils.createLocationSearchParam(
          ORDER_SERVICE_PROVIDER_TYPE.none,
          'ne'
        );
        break;
      }
      case ORDER_SERVICE_PROVIDER_TYPE.internalTechnician: {
        locationSearchObject['assignInfo.providerType'] = NavigateFrontendUtils.createLocationSearchParam(
          ORDER_SERVICE_PROVIDER_TYPE.internalTechnician
        );
        break;
      }
      case ORDER_SERVICE_PROVIDER_TYPE.serviceProvider: {
        locationSearchObject['assignInfo.providerType'] = NavigateFrontendUtils.createLocationSearchParam(
          ORDER_SERVICE_PROVIDER_TYPE.serviceProvider
        );
        break;
      }
      case ORDER_OPERATOR_TYPE.platformOperator: {
        locationSearchObject['assignInfo.operatorType'] = NavigateFrontendUtils.createLocationSearchParam(
          ORDER_OPERATOR_TYPE.platformOperator
        );
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(providerTypeValue);
      }
    }
    delete locationSearchObject[ORDER_FILTER.providerType];
  };

  /** Мутирует переданный объект! */
  private static handleTechnicianAssignStatusFilter = (locationSearchObject: LocationSearchObject) => {
    type TechnicianAssignStatusValue = ReturnType<typeof useTechnicianAssignStatusFilterOptions>[0]['id'];
    const technicianAssignStatusValue = locationSearchObject[ORDER_FILTER.technicianAssignStatus]?.value as TechnicianAssignStatusValue;
    switch (technicianAssignStatusValue) {
      case ORDER_TECHNICIANS_ASSIGN_STATUS.none:
      case ORDER_TECHNICIANS_ASSIGN_STATUS.partial:
      case ORDER_TECHNICIANS_ASSIGN_STATUS.full: {
        locationSearchObject['assignInfo.technicianAssignStatus'] =
          NavigateFrontendUtils.createLocationSearchParam(technicianAssignStatusValue);
        break;
      }
      case 'selected': {
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(technicianAssignStatusValue);
      }
    }
    delete locationSearchObject[ORDER_FILTER.technicianAssignStatus];
  };

  /** Мутирует переданный объект! */
  private static handleInstallationDateFilter = (locationSearchObject: LocationSearchObject, dateUtils: DateUtils) => {
    const fromIntervalFieldName: keyof DateIntervalDTO = 'from';
    const toIntervalFieldName: keyof DateIntervalDTO = 'to';
    const fromParamName = ORDER_FILTER.installationDate + '.' + fromIntervalFieldName + this.APPLIED_TIMEZONE_FIELD_NAME;
    const toParamName = ORDER_FILTER.installationDate + '.' + toIntervalFieldName + this.APPLIED_TIMEZONE_FIELD_NAME;

    // Идёт поиск по специальному полю на беке, в котором дата стоит в зоне заказа, но при этом помечена нулевой зоной, поэтому здесь я должен сделать то же самое
    switch (locationSearchObject[ORDER_FILTER.installationDateType]?.value as ORDER_FILTER_DATE) {
      case ORDER_FILTER_DATE.afterCurrent: {
        const dateZoned = dateUtils.changeTimezone(new Date(), 'Zulu' as TIME_ZONE);
        locationSearchObject[fromParamName] = NavigateFrontendUtils.createLocationSearchParam(dateZoned.toISOString(), 'gt');
        break;
      }
      case ORDER_FILTER_DATE.beforeCurrent: {
        const dateZoned = dateUtils.changeTimezone(new Date(), 'Zulu' as TIME_ZONE);
        locationSearchObject[toParamName] = NavigateFrontendUtils.createLocationSearchParam(dateZoned.toISOString(), 'lt');
        break;
      }
      case ORDER_FILTER_DATE.between: {
        const datesParam = locationSearchObject[ORDER_FILTER.installationDate];
        if (!datesParam) {
          break;
        }
        const [dateFrom, dateTo] = datesParam.values;
        const dateFromZoned = dateUtils.changeTimezone(new Date(dateFrom), 'Zulu' as TIME_ZONE).toISOString();
        const dateToZoned = dateUtils.changeTimezone(new Date(dateTo), 'Zulu' as TIME_ZONE).toISOString();
        locationSearchObject[fromParamName] = NavigateFrontendUtils.createLocationSearchParam([dateFromZoned, dateToZoned], 'be');
        locationSearchObject[toParamName] = NavigateFrontendUtils.createLocationSearchParam([dateFromZoned, dateToZoned], 'be');
        break;
      }
      case ORDER_FILTER_DATE.overdue: {
        // TODO
        break;
      }
      case ORDER_FILTER_DATE.exist: {
        locationSearchObject[ORDER_FILTER.installationDate] = NavigateFrontendUtils.createLocationSearchParam(
          [ORDER_FILTER_DATE.exist],
          'nn'
        );
        break;
      }
      case ORDER_FILTER_DATE.notExist: {
        locationSearchObject[ORDER_FILTER.installationDate] = NavigateFrontendUtils.createLocationSearchParam(
          [ORDER_FILTER_DATE.notExist],
          'nl'
        );
        break;
      }
    }

    if (
      locationSearchObject[ORDER_FILTER.installationDateType]?.value !== ORDER_FILTER_DATE.exist &&
      locationSearchObject[ORDER_FILTER.installationDateType]?.value !== ORDER_FILTER_DATE.notExist
    ) {
      delete locationSearchObject[ORDER_FILTER.installationDate];
    }

    delete locationSearchObject[ORDER_FILTER.installationDateType];

    switch (locationSearchObject[ORDER_FILTER.visitCompletionState]?.value) {
      case ORDER_COMPLETION_STATE_NOT_OVERDUE: {
        locationSearchObject[ORDER_FILTER.visitCompletionState] = NavigateFrontendUtils.createLocationSearchParam(
          [ORDER_VISIT_DATE_COMPLETION_STATE.bad, ORDER_VISIT_DATE_COMPLETION_STATE.warn],
          'ni'
        );
        break;
      }
    }
  };

  /**
   * Спец. значения могут быть во многих фильтрах.
   * Мутирует переданный объект!
   */
  private static handleSpecialValues = (locationSearchObject: LocationSearchObject) => {
    Object.keys(locationSearchObject).forEach((key) => {
      const paramRaw = locationSearchObject[key];
      if (!paramRaw) {
        return;
      }

      const param = { ...paramRaw };
      const paramSpValue = param.values.find((v) => this.orderFilterSpecialValues.includes(v as ORDER_FILTER_SPECIAL_VALUE));
      if (!paramSpValue) {
        return;
      }

      const lastIndexOfDivider = key.lastIndexOf('.');
      if (lastIndexOfDivider === -1) {
        return;
      }

      if (paramSpValue === ORDER_FILTER_SPECIAL_VALUE.exist) {
        param.values = [ORDER_FILTER_SPECIAL_VALUE.exist];
        param.matchType = 'nn';
        // Такая особенность бека, что чтобы проверить на "не пустоту" нужно передать ключ без вложенность, например departments.id становится departments
        // TODO Пока берётся просто первое слово до точки, но это потенциальные ошибки в будущем
        delete locationSearchObject[key];
        locationSearchObject[key.slice(0, lastIndexOfDivider)] = param;
      } else if (paramSpValue === ORDER_FILTER_SPECIAL_VALUE.notExist) {
        param.values = [ORDER_FILTER_SPECIAL_VALUE.notExist];
        param.matchType = 'nl';
        delete locationSearchObject[key];
        locationSearchObject[key.slice(0, lastIndexOfDivider)] = param;
      }
    });
  };

  // Тут не учитывается кейс фейковых статусов заказа, на бек отправляется как есть! Всё равно надо менять весь механизм сохранения запроса во view
  /** Маппит поля сущности из адреса в массив, из фронтовых названий в ResponseDTO (но при условии, что такое поле есть в маппере, иначе выбрасывает его) */
  public static locationSearchObjectToViewFilters = <T extends Model, DTO extends ModelDTOResponse>(
    locationSearchObject: LocationSearchObject,
    mapper: IMapper<T, DTO>,
    customFieldsConfigsSet: SetCustom<CustomField>
  ): ViewFilterDTO[] => {
    const fields = Object.keys(locationSearchObject);
    const viewFilters: ViewFilterDTO[] = [];
    fields.forEach((field) => {
      const mappedKey = mapper.getDBResponseFieldName(field as keyof Model, true, true);
      const locationParam = locationSearchObject[field];
      // Работаем только если что-то вернулось из мапера, но т.к. сейчас мапится только первое поле, то проверяем только его
      // Но это всё фронтовая самодеятельность, т.к. у бека нет контракта по каким полям можно фильтровать, поэтому пока я привязался к названиям dto;
      // Но стали появляться кастомные параметры и их надо не потерять
      // Также появились кастомные поля заказа
      if ((mappedKey || ORDER_FILTER_CUSTOM_FIELDS.has(field) || customFieldsConfigsSet.getByKey(field)) && locationParam) {
        viewFilters.push({
          technicalName: mappedKey || field,
          values: locationParam.values,
          multiple: locationParam.valueType === 'array',
          matchType: locationParam.matchType,
        });
      }
    });
    return viewFilters;
  };

  // Back to front mapper -----------------------------------------------------------------------------------

  /** Маппит названия из формата ResponseDTO в модель (но при условии, что такое поле есть в маппере, иначе выбрасывает его) и приводит всё к виду LocationSearchObject */
  public static viewFiltersToLocationSearchObject = <T extends Model, DTO extends ModelDTOResponse>(
    viewFilters: ViewFilterDTO[],
    mapper: IMapper<T, DTO>,
    customFieldsConfigsSet: SetCustom<CustomField>
  ): LocationSearchObject => {
    const searchQueryStr = viewFilters
      .map((vf) => {
        // Работаем только если что-то вернулось из мапера, но т.к. сейчас мапится только первое поле, то проверяем только его
        // Но это всё фронтовая самодеятельность, т.к. у бека нет контракта по каким полям можно фильтровать, поэтому пока я привязался к названиям dto;
        // Но стали появляться кастомные параметры и их надо не потерять
        let mappedKey = mapper.getModelFieldName(vf.technicalName as keyof DTO, true);

        if (!mappedKey) {
          if (ORDER_FILTER_CUSTOM_FIELDS.has(vf.technicalName)) mappedKey = vf.technicalName as any;
          else if (customFieldsConfigsSet.getByKey(vf.technicalName)) mappedKey = vf.technicalName as any;
          else return '';
        }

        // у multiple вначале значений должна идти запятая
        const listLabel = vf.multiple ? ',' : '';
        return mappedKey + '=' + listLabel + vf.values.join(',') + ';' + vf.matchType;
      })
      .join('&');
    return NavigateFrontendUtils.getLocationSearchObjectFromQueryStr(searchQueryStr);
  };
}

type OrderTab = {
  title: string;
  value: string;
  count?: number;
  pre?: React.FC;
};
