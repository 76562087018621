import Mapper from './Mapper';
import { CreateMatchingConfigRequestDTO, MatchingConfigResponseDTO } from 'typings/dto/matchingConfig';

export default class MatchingConfigMapper extends Mapper<MatchingConfig, MatchingConfigResponseDTO, CreateMatchingConfigRequestDTO> {
  constructor() {
    super(
      {
        brand: 'brand',
        displayOnWidget: 'displayOnWidget',
        properties: 'properties',
        serviceItem: 'serviceItem',
        tenant: 'tenant',
      },
      null
    );
  }
}
