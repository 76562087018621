import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { batch as reduxBatch } from 'react-redux';
import IRecommendationsConfigActionsService from 'services/storageModelActions/recommentationsConfig/IRecommendationsConfigActionsService';
import {
  RecommendationConfigRequestDTO,
  RecommendationConfigResponseDTO,
  UpdateRecommendationConfigResponseDTO,
} from 'typings/dto/recommendationsConfig';

export default class RecommendationsConfigActionsServiceImpl
  extends ModelActionsService<RecommendationsConfig, RecommendationConfigRequestDTO, RecommendationConfigResponseDTO>
  implements IRecommendationsConfigActionsService
{
  public async getRecommendationsConfig(): Promise<void> {
    this.storageDispatch(this.modelStorageActions.startLoading());
    await reduxBatch(async () => {
      try {
        const response = await this.entityApiService
          .getWithCredentials<RecommendationConfigResponseDTO>(this.getModelApiRootPath(''))
          .then(this.modelMapper.responseDTOToModel);
        if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setOne(response));
        else console.warn(`Get list request ${this.modelStorageName} is outdated`);
      } catch (error) {
        if (!this.areActionsOutdated) {
          console.error(error);
          this.storageDispatch(this.modelStorageActions.setError(error));
        }
      }
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.stopLoading());
    });
  }

  public async updateRecommendationsConfig(dto: RecommendationsConfig): Promise<UpdateRecommendationConfigResponseDTO> {
    const response = await this.entityApiService.putWithResponse<UpdateRecommendationConfigResponseDTO>(
      this.getModelApiRootPath(dto),
      this.modelMapper.modelToRequestDTO(dto, null)
    );
    this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
    return response;
  }
}
