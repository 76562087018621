import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD as P, PAGE_TYPE, PATH_PARAM_TOKEN, PATH_PARAM_DASHBOARD } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';
import { DEFAULT_ORDER_VIEW_NAME } from 'typings/models/order/orderView.enum';
import NavigateFrontendUtils from 'utils/NavigateFrontend';

// Это первый роутинг сервис где вшита передача location.search, в других разделах он поддерживался через хук, но похоже что так лучше
export default class OrderRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(P.order.root);
  public service = new ServiceRoutes();
  public report = new ReportRoutes();
  public review = new ReviewRoutes();
  public task = new TaskRoutes();
  public history = new HistoryRoutes();
  public comment = new CommentRoutes();
  public providerTransactionsData = new ProviderTransactionsDataRoutes();
  public document = new DocumentRoutes();

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.order> = (_params, _permissions, translate) => {
    return [{ name: translate('menus.main.orders'), href: this.root }];
  };
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(P.order.root, PAGE_TYPE.add);

  getAddLinkBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.order, 'orderNumber'> = (p, _pm, _t, n) => {
    return [{ name: n.orderNumber, href: this.getInfoPathAbsolute(p.orderId || '') }];
  };
  getAddLinkPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params) =>
    this.getDBPath(P.order.root, PAGE_TYPE.add, params.orderId, PATH_PARAM_DASHBOARD.order.linked);

  getAddComplaintBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.order, 'orderNumber'> = (p, _pm, _t, n) => {
    return [{ name: n.orderNumber, href: this.getInfoPathAbsolute(p.orderId || '') }];
  };
  getAddComplaintPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params) =>
    this.getDBPath(P.order.root, PAGE_TYPE.add, params.orderId, PATH_PARAM_DASHBOARD.order.complaint);

  getInfoBc = this.getEmptyBreadcrumbs;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.main, PAGE_TYPE.info);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getInfoPathAbsolute = (orderId: string) =>
    this.getDBPath(P.order.root, DEFAULT_ORDER_VIEW_NAME.all, orderId, P.order.main, PAGE_TYPE.info);
}

class ServiceRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.services, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.services, PAGE_TYPE.new, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getEditBc = this.getEmptyBreadcrumbs;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.services, params.serviceId, PAGE_TYPE.edit);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class ReportRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.report, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.report, PAGE_TYPE.new, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getEditBc = this.getEmptyBreadcrumbs;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.report, params.reportId, PAGE_TYPE.edit);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class ReviewRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getInfoBc = this.getEmptyBreadcrumbs;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.review, PAGE_TYPE.info);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.review, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getEditBc = this.getEmptyBreadcrumbs;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.review, PAGE_TYPE.edit);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class TaskRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.tasks, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.tasks, PAGE_TYPE.new, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getEditBc = this.getEmptyBreadcrumbs;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.tasks, params.taskId, PAGE_TYPE.edit);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class ProviderTransactionsDataRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.providerTransactions, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(
      P.order.root,
      params.orderViewTechName,
      params.orderId,
      P.order.providerTransactions,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class HistoryRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.history, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class CommentRoutes extends FrontendRoutesBase {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.comments, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class DocumentRoutes extends FrontendRoutesBase {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.order> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.order.root, params.orderViewTechName, params.orderId, P.order.documents, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}
