import * as Yup from 'yup';
import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import AutocompleteMemorized from 'components/ui/forms/AutocompleteMemorized';
import useDI from 'hooks/useDI';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import FormProvider from 'components/ui/forms/FormProvider';
import useMediaQuery from 'hooks/useMediaQuery';
import { Button, useTheme } from '@mui/material';
import { INPUT_SMALL_WIDTH_CLASS_NAME } from 'configs/layout';
import { CloseIcon } from 'components/ui/icons';
import SearchFieldMemorized from 'components/ui/forms/SearchFieldMemorized';

type Props = {
  initialRules: RecommendationRule[];

  isPageInitialized: boolean;
  serviceList: BusinessService[];
  setRulesList: React.Dispatch<React.SetStateAction<RecommendationRule[]>>;
};

export default function RecommendationRuleListFilter({ initialRules, isPageInitialized, serviceList, setRulesList }: Props) {
  const { services } = useDI();
  const { translate } = services.language;
  const handleFormErrors = useSnackbarErrorHandler();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const categoriesList = [...new Map(serviceList.map((service) => [service.category.id, service.category])).values()];

  const [initialValues, validationSchema] = useMemo(() => {
    const initialValues = {
      name: '',
      categories: null as null | EntityWithName,
    };
    const validationSchema = Yup.object().shape({
      name: Yup.string(),
      categories: Yup.mixed(),
    });
    return [initialValues, validationSchema];
  }, [isPageInitialized, categoriesList.length]);

  const resetActionHandler = () => {
    setRulesList(initialRules);
    formState.setFieldValue('categories', null);
    formState.setFieldValue('name', '');
  };

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        let selectedRules = [...initialRules];
        if (values.categories) {
          selectedRules = [...selectedRules.filter((rule) => values.categories && rule.serviceCategory.id === values.categories.id)];
        }
        if (values.name) {
          selectedRules = [
            ...selectedRules.filter((rule) => values.name && rule.serviceItem.name.toLowerCase().includes(values.name.toLowerCase())),
          ];
        }
        setRulesList(selectedRules);

        if (values.categories === null && values.name === '') {
          resetActionHandler();
        }
      } catch (error) {
        handleFormErrors({ error });
      }
    },
  });

  // PageFilterFormProvider использовать не получится, так как в нем все через locationSearchObject, а тут другая логика. Но выглядеть должно так же.
  // TODO если в проекте будут появляться еще места где фильтр будет работать по своей логике, а не через locationSearchObject - вынести это в отдельный компонент по аналогии с PageFilterFormProvider
  return (
    <FormProvider
      containerSx={{ mt: 4 }}
      formState={formState}
      direction="row"
      className={!isMobile ? INPUT_SMALL_WIDTH_CLASS_NAME : ''}
      sx={{
        display: { xs: 'grid', md: 'flex' },
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        gridTemplateColumns: '1fr',
        gap: 2,
      }}
    >
      <SearchFieldMemorized
        formState={formState}
        label={translate('entities.recommendations.rule.serviceItemName')}
        fieldName="name"
        submitOnBlur
        size="small"
      />
      <AutocompleteMemorized
        fieldName="categories"
        label={translate('entities.recommendations.rule.categoryName')}
        formState={formState}
        isLoading={!isPageInitialized}
        options={categoriesList}
        autosubmit
        size="small"
        fullWidth={isMobile}
      />
      {formState.values.categories && (
        <Button endIcon={<CloseIcon />} variant="text" onClick={resetActionHandler} sx={{ color: (theme) => theme.palette.text.secondary }}>
          {translate('buttons.reset')}
        </Button>
      )}
    </FormProvider>
  );
}
