export enum NOTIFICATION_TYPE {
  ORDER_SP_ASSIGNED = 'ORDER_SP_ASSIGNED',
  ORDER_TECHNICIAN_ASSIGNED = 'ORDER_TECHNICIAN_ASSIGNED',
  ORDER_UNASSIGNED_FOR_TECHNICIAN = 'ORDER_UNASSIGNED_FOR_TECHNICIAN',
  ORDER_TECHNICIAN_UNASSIGNED = 'ORDER_TECHNICIAN_UNASSIGNED',
  NEW_ORDER_ASSIGNED_FOR_SP = 'NEW_ORDER_ASSIGNED_FOR_SP',
  NEW_ORDER_ASSIGNED_FOR_TECHNICIAN = 'NEW_ORDER_ASSIGNED_FOR_TECHNICIAN',
  ORDER_PROPOSED = 'ORDER_PROPOSED',
  ORDER_COMMENT_ADDED = 'ORDER_COMMENT_ADDED',
  ORDER_BAD_REVIEW_ADDED = 'ORDER_BAD_REVIEW_ADDED',
  ORDER_GOOD_REVIEW_ADDED = 'ORDER_GOOD_REVIEW_ADDED',
  ORDER_REVIEW_COMMENT_ADDED = 'ORDER_REVIEW_COMMENT_ADDED',
  ORDER_REPORT_FILLED = 'ORDER_REPORT_FILLED',
  ORDER_STATUS_CHANGED_TO_IN_PROGRESS = 'ORDER_STATUS_CHANGED_TO_IN_PROGRESS',
  ORDER_STATUS_CHANGED_TO_PAUSED = 'ORDER_STATUS_CHANGED_TO_PAUSED',
  ORDER_STATUS_CHANGED_TO_CANCELLED = 'ORDER_STATUS_CHANGED_TO_CANCELLED',
  ORDER_STATUS_CHANGED_TO_WORK_FINISHED = 'ORDER_STATUS_CHANGED_TO_WORK_FINISHED',
  ORDER_STATUS_CHANGED_TO_COMPLETED = 'ORDER_STATUS_CHANGED_TO_COMPLETED',
  ORDER_WORK_STARTED = 'ORDER_WORK_STARTED',
  ORDER_WORK_FINISHED = 'ORDER_WORK_FINISHED',
  ORDER_MARKED_AS_PROBLEMATIC = 'ORDER_MARKED_AS_PROBLEMATIC',
  ORDER_UNMARKED_AS_PROBLEMATIC = 'ORDER_UNMARKED_AS_PROBLEMATIC',
  ORDER_VISIT_DATE_CHANGED = 'ORDER_VISIT_DATE_CHANGED',
  ORDER_VISIT_DATES_CHANGED = 'ORDER_VISIT_DATES_CHANGED',
  ORDER_PERSONAL_TASK_ADDED = 'ORDER_PERSONAL_TASK_ADDED',
  ORDER_GROUP_TASK_ADDED = 'ORDER_GROUP_TASK_ADDED',
  ORDER_TECHNICIAN_AUTO_ASSIGN_FAILED = 'ORDER_TECHNICIAN_AUTO_ASSIGN_FAILED',
  ORDER_DECLINED_BY_TECHNICIAN = 'ORDER_DECLINED_BY_TECHNICIAN',
  ORDER_TASK_STATUS_CHANGED_TO_OVERDUE = 'ORDER_TASK_STATUS_CHANGED_TO_OVERDUE',
  ORDER_TASK_STATUS_CHANGED_TO_COMPLETED = 'ORDER_TASK_STATUS_CHANGED_TO_COMPLETED',
  ORDER_TECHNICIAN_IS_LATE = 'ORDER_TECHNICIAN_IS_LATE',
  ORDER_TECHNICIAN_NOT_SHOW = 'ORDER_TECHNICIAN_NOT_SHOW',
  ORDER_NOT_FINISHED_ON_TIME = 'ORDER_NOT_FINISHED_ON_TIME',
  ORDER_SERVICE_ITEM_QUANTITY_CHANGED = 'ORDER_SERVICE_ITEM_QUANTITY_CHANGED',
  ORDER_SERVICE_ITEM_DISCOUNT_CHANGED = 'ORDER_SERVICE_ITEM_DISCOUNT_CHANGED',
  CONTRACT_REGIONS_CATEGORIES_CHANGED = 'CONTRACT_REGIONS_CATEGORIES_CHANGED',
  CONTRACT_REGION_CHANGED = 'CONTRACT_REGION_CHANGED',
  CONTRACT_CATEGORIES_CHANGED = 'CONTRACT_CATEGORIES_CHANGED',
  CONTRACT_PRICELIST_CHANGED = 'CONTRACT_PRICELIST_CHANGED',
  CONTRACT_RESUMED = 'CONTRACT_RESUMED',
  CONTRACT_SUSPENDED = 'CONTRACT_SUSPENDED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CONTRACT_CREATED = 'CONTRACT_CREATED',
  ORDER_CUSTOMER_BALANCE_TOPPED_UP_BY_CARD = 'ORDER_CUSTOMER_BALANCE_TOPPED_UP_BY_CARD',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_ACTION_FAILED = 'ORDER_ACTION_FAILED',
  CUSTOM_SMS = 'CUSTOM_SMS',
  ORDER_VOXIMPLANT_INCOME_CALL = 'ORDER_VOXIMPLANT_INCOME_CALL',
  ORDER_VOXIMPLANT_OUTCOME_CALL = 'ORDER_VOXIMPLANT_OUTCOME_CALL',
}

export enum NOTIFICATION_PAYLOAD_TYPE {
  order = 'ORDER',
  contract = 'CONTRACT',
}

export enum NOTIFICATION_ELEMENTS {
  CRM = 'CRM',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  ORDER_TYPE = 'orderType',
  GROUP = 'group',
  OTHER_NOTIFICATION_SETTIGS = 'otherNotificationSettings',
}

export enum NOTIFICATION_AUDIENCE {
  GUEST = 'GUEST',
  USER = 'USER',
}
