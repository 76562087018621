import DIContainer from 'services/DIContainer';
import Mapper from './Mapper';
import { OrderCustomFieldRequestDTO } from 'typings/dto/order';
import { CustomFieldResponseDTO } from 'typings/dto/customField';

export default class CustomFieldMapper extends Mapper<CustomField, CustomFieldResponseDTO, OrderCustomFieldRequestDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        name: 'name',
        editable: 'editable',
        multiple: 'multiple',
        required: 'required',
        settings: 'settings',
        technicalName: 'technicalName',
        type: 'type',
        dynamicFieldSettings: 'dynamicField',
        tenant: 'tenant',
        category: 'category',
      },
      null
    );
  }

  public responseDTOToModel = (dto: CustomFieldResponseDTO): CustomField => {
    const outerMappers = this.getOuterMappers();
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      ...this.subEntitiesMappers.orderCustomField.responseDTOToModel(dto, outerMappers),
    };
  };

  public responseDTOToModelIdAndNamesOnly = (dto: CustomFieldResponseDTO): CustomField => {
    return this.responseDTOToModel(dto);
  };
}
