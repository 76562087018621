export enum CONTRACT_TYPE {
  executor = 'executor',
  agent = 'agent',
}

export enum WEBHOOK_API_AUTH_TYPE {
  none = 'NONE',
  basic = 'BASIC',
}

export enum GEO_POINT_TRACKING_TYPE {
  REQUIRED = 'REQUIRED',
  DISABLED = 'DISABLED',
  OPTIONAL = 'OPTIONAL',
}

export enum ASSIGN_ENTITY_TYPE {
  TECHNICIAN = 'TECHNICIAN',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  PLATFORM_OPERATOR = 'PLATFORM_OPERATOR',
}
