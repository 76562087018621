import { CreateServiceRequestDTO, ServiceResponseDTO } from 'typings/dto/service';
import Mapper from './Mapper';
import EnterpriseUtils from 'utils/models/EnterpriseUtils';

export default class ServiceMapper extends Mapper<BusinessService, ServiceResponseDTO, CreateServiceRequestDTO> {
  constructor(private currentUserGetter: () => User | null) {
    super(
      {
        description: 'description',
        includedText: 'included',
        name: 'name',
        notIncludedText: 'notIncluded',
        serviceCategory: 'category',
        technicalName: 'technicalName',
        workEstimation: 'workEstimation',
        tenant: 'tenant',
      },
      null
    );
  }

  public responseDTOToModel(dto: ServiceResponseDTO): BusinessService {
    const currentUser = this.currentUserGetter();

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      workEstimation:
        typeof dto.workEstimation?.durationMinutes === 'number' ? { durationMinutes: dto.workEstimation.durationMinutes! } : undefined,
      isInherited: EnterpriseUtils.isEntityInherited(currentUser, dto),
    };
  }
}
