import { FIELD_PERMISSION } from 'configs/permissions/fieldPermissions';
import ILanguageService from 'services/language/ILanguageService';
import { ORDER_TECHNICIANS_ASSIGN_STATUS } from 'typings/models/order/order.enum';
import { PROMOTIONS_DISCOUNT_TYPE } from 'typings/models/promotionsConfig.enum';
import { PRICE_TYPE, PRICE_VALUE_TYPE } from 'typings/models/price.enum';
import { SERVICE_PAYMENT_TYPE } from 'typings/models/serviceOrdered.enum';
import FunctionUtils from 'utils/Function';
import MoneyUtils from 'utils/Money';

export default class ServiceOrderedUtils {
  //Этот идентификатор добавляется к настоящему id услуги для дублирования в массиве всех услуг на странице добавления в заказ.
  // Нужно для уникальной идентификации какую услугу открыть. После создания услуги - оригинальная и дубликат объединяются в одну
  private static duplicateIndentificator = '-duplicated';

  public static canBePaid = (service: ServiceOrdered): boolean => {
    if (service.isFree) {
      return false;
    }
    return !service.isPaid;
  };

  public static canBeUnPaid = (service: ServiceOrdered): boolean => {
    if (service.isFree) {
      return false;
    }
    return service.isPaid;
  };

  public static canBeDeleted = (service: ServiceOrdered): boolean => {
    if (service.isFree) {
      return true;
    }
    return !service.isPaid;
  };

  public static canBeEdited = (service: ServiceOrdered): boolean => {
    if (service.isFree) {
      return false;
    }
    return !service.isPaid;
  };

  public static count = (list: ServiceOrdered[]) => {
    const counted = {
      totalPriceClient: 0,
      unpaidTotalPriceClient: 0,
      paidTotalPriceClient: 0,
      totalPriceProvider: 0,
      totalProviderSaleBonus: 0,
      totalPricePO: 0,
      totalPOBonus: 0,
      totalDiscount: 0,
    };
    list.forEach((service) => {
      counted.totalPriceClient += service.price.totalClientValue;
      counted.totalPriceProvider += service.price.totalProviderValue;
      counted.totalProviderSaleBonus += (service.price.providerSaleBonus || 0) * 0.01 * service.price.totalProviderValue;
      counted.totalPricePO += service.price.platformOperatorReward || 0;
      counted.totalPOBonus += service.price.platformOperatorBonus || 0;
      if (!service.isPaid) {
        counted.unpaidTotalPriceClient += service.price.totalClientValue;
      } else {
        counted.paidTotalPriceClient += service.price.totalClientValue;
      }
      // Общая скидка по всем доступным акциям для услуги помноженная на количество
      const promotionDiscountsValue = ((service.promotion?.priceBefore || 0) - (service.promotion?.priceAfter || 0)) * service.quantity;
      counted.totalDiscount += service.price.initialValue * service.quantity - service.price.totalClientValue + promotionDiscountsValue;
    });
    return counted;
  };

  public static priceToServiceOrdered = (price: Price): ServiceOrdered => {
    let providerPriceValue: PriceValue | null;
    let providerSaleBonus: number;
    switch (price.type) {
      case PRICE_TYPE.serviceProvider: {
        providerPriceValue = price.attributes.serviceProviderPrice;
        providerSaleBonus = price.attributes.saleBonus;
        break;
      }
      case PRICE_TYPE.technician: {
        providerPriceValue = price.attributes.technicianPrice;
        providerSaleBonus = price.attributes.saleBonus;
        break;
      }
      case PRICE_TYPE.client: {
        providerPriceValue = null;
        providerSaleBonus = 0;
        break;
      }
      case PRICE_TYPE.platformOperator: {
        providerPriceValue = price.attributes.platformOperatorPrice;
        providerSaleBonus = 0;
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(price);
        providerPriceValue = null;
        providerSaleBonus = 0;
      }
    }
    return {
      id: price.id,
      createdAt: price.createdAt,
      updatedAt: price.updatedAt,
      createdAtNumber: price.createdAtNumber,
      updatedAtNumber: price.updatedAtNumber,
      isCustom: false,
      isPaid: false,
      name: price.name,
      price: {
        currency: price.currency,
        discount: { type: 'PERCENT', value: 0 },
        initialValue: price.clientValue && price.clientValue.type === PRICE_VALUE_TYPE.amount ? price.clientValue?.value || 0 : 0,
        providerValue: providerPriceValue?.value || 0,
        totalClientValue: providerPriceValue?.value || 0,
        totalProviderValue: providerPriceValue?.value || 0,
        clientPrice: price.clientValue,
        providerPrice: providerPriceValue,
        providerSaleBonus: providerSaleBonus,
        platformOperatorReward: price.type === PRICE_TYPE.technician ? price.attributes.platformOperatorPrice : undefined,
      },
      promotion: price.promotion,
      productPrice: 0,
      quantity: 1,
      technicalName: price.service.technicalName,
      //Убираем duplicateIdentificator потому что catalogId используется для запроса на backend. Там нужен настоящий id.
      catalogId: price.service.id.replace(ServiceOrderedUtils.duplicateIndentificator, ''),
      description: price.service.description,
      notIncluded: price.service.notIncluded,
      included: price.service.included,
      isFree: price.clientValue.value === 0,
      workEstimation: price.service.workEstimation,
      linkedEntities: [],
    };
  };

  public static paymentTypeToPermission = (paymentType: SERVICE_PAYMENT_TYPE): FIELD_PERMISSION => {
    switch (paymentType) {
      case SERVICE_PAYMENT_TYPE.cashOnSite: {
        return FIELD_PERMISSION.CAN_PAY_ORDER_SERVICE_BY_CASH_ON_SITE;
      }
      case SERVICE_PAYMENT_TYPE.store: {
        return FIELD_PERMISSION.CAN_PAY_ORDER_SERVICE_IN_STORE;
      }
      case SERVICE_PAYMENT_TYPE.balance: {
        return FIELD_PERMISSION.CAN_PAY_ORDER_SERVICE_FROM_BALANCE;
      }
      case SERVICE_PAYMENT_TYPE.card: {
        return FIELD_PERMISSION.CAN_PAY_ORDER_SERVICE_BY_CARD;
      }
    }
  };

  public static isInfoEmpty = (service: ServiceOrdered) => {
    return !service.description && !service.notIncluded && !service.included;
  };

  /** Нужно проверить, выводить рассчитанное вознаграждение или подсказку, о том, что чего-то не хватает */
  public static isTechniciansRewardAvailable = (order: Order) => {
    return Boolean(order.technicians.length) && order.serviceProviderInfo.technicianAssignStatus === ORDER_TECHNICIANS_ASSIGN_STATUS.full;
  };

  public static servicesToServiceCategoryWithServicesWithServiceNameFilter = (
    services: Price[],
    serviceNameFilter?: string
  ): Array<ServiceCategoryWithServices> => {
    let groupedServices: Record<string, Price[]> = services.reduce((acc: Record<string, Price[]>, service: Price) => {
      if (!acc[service.service.category.name]) {
        acc[service.service.category.name] = [];
      }
      if (serviceNameFilter && serviceNameFilter !== '' && service.name.toLowerCase().includes(serviceNameFilter.toLowerCase())) {
        acc[service.service.category.name].push(service);
      } else if (!serviceNameFilter) {
        acc[service.service.category.name].push(service);
      }
      return acc;
    }, {});
    return Object.entries(groupedServices)
      .filter(([, services]) => services && services.length !== 0)
      .map(([categoryName, services]) => {
        return { categoryName, services };
      });
  };

  public static duplicatePrice = (prices: Price[], priceId: string) => {
    const index = prices.findIndex((p) => p.id === priceId);
    const duplicatedPrice = {
      ...prices[index],
      id: prices[index].id + ServiceOrderedUtils.duplicateIndentificator,
      service: {
        ...prices[index].service,
        id: prices[index].service.id + ServiceOrderedUtils.duplicateIndentificator,
      },
    };
    prices.splice(index + 1, 0, duplicatedPrice);
    return duplicatedPrice;
  };

  public static getDiscounts = (discounts: PromotionDiscounts[], moneyUtils: MoneyUtils, serviceLanguage: ILanguageService) => {
    return discounts.map((discount) => ({
      discountValue:
        discount.type === PROMOTIONS_DISCOUNT_TYPE.additive
          ? '-' + moneyUtils.toString(discount.amount, serviceLanguage.getCurrentLocale().localeCurrency, true)
          : '-' + discount.amount + '%',
      discountDescriprion: discount.message,
    }));
  };

  public static getDiscountsValue = (discounts: PromotionDiscounts[], moneyUtils: MoneyUtils, serviceLanguage: ILanguageService) => {
    return discounts.map((discount) =>
      discount.type === PROMOTIONS_DISCOUNT_TYPE.additive
        ? '-' + moneyUtils.toString(discount.amount, serviceLanguage.getCurrentLocale().localeCurrency, true)
        : '-' + discount.amount + '%'
    );
  };
}
