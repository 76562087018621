import { CreateOrderViewRequestDTO, OrderViewResponseDTO } from 'typings/dto/orderView';
import { ORDER_CUSTOM_VISIBILITY_FIELD } from 'typings/models/order/orderView.enum';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import SetCustom from 'utils/implementations/SetCustom';
import OrderViewUtils from 'utils/models/OrderViewUtils';
import Mapper from './Mapper';

export default class OrderViewMapper extends Mapper<OrderView, OrderViewResponseDTO, CreateOrderViewRequestDTO> {
  constructor(private currentUserGetter: () => User | null, private customFieldGetter: () => CustomField[]) {
    super(
      {
        name: 'name',
        technicalName: 'technicalName',
        type: 'type',
        viewOrderFieldList: 'hiddenFields',
        tenantType: 'tenantType',
        viewFilters: 'locationSearchObject',
        sharedDepartments: 'sharedDepartments',
        sharedPositions: 'sharedPositions',
        ownerId: 'createdById',
      },
      null
    );
  }

  public responseDTOToModel(dto: OrderViewResponseDTO): OrderView {
    const orderMapper = this.getOuterMappers().order;
    let customFieldsConfigsSet: SetCustom<CustomField>;
    const currentUser = this.currentUserGetter();
    const customFields = this.customFieldGetter();
    if (currentUser?.type === TENANT_TYPE.enterprise || currentUser?.type === TENANT_TYPE.platformOperator) {
      customFieldsConfigsSet = new SetCustom(customFields, (i) => i.id);
    } else {
      customFieldsConfigsSet = new SetCustom();
    }

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      locationSearchObject: OrderViewUtils.viewFiltersToLocationSearchObject(dto.viewFilters, orderMapper, customFieldsConfigsSet),
      hiddenFields: this.hiddenFieldsToModel(dto.viewOrderFieldList),
      isShared: dto.sharedDepartments.length > 0 || dto.sharedPositions.length > 0,
    };
  }

  public hiddenFieldsToModel = (dto: OrderViewResponseDTO['viewOrderFieldList']): OrderView.HiddenFields => {
    const hiddenFields: OrderView.HiddenFields = {};
    dto.forEach((fieldName) => {
      hiddenFields[fieldName] = true;
    });
    return hiddenFields;
  };

  public hiddenFieldsToDTO = (
    model: OrderView.HiddenFields,
    knownFieldsSet: Set<ORDER_CUSTOM_VISIBILITY_FIELD>,
    customFieldsSet: SetCustom<CustomField>
  ): OrderViewResponseDTO['viewOrderFieldList'] => {
    const hiddenOrderFields: ORDER_CUSTOM_VISIBILITY_FIELD[] = [];
    Object.keys(model).forEach((fieldName) => {
      if (model[fieldName as ORDER_CUSTOM_VISIBILITY_FIELD]) {
        // Здесь отсеиваются мусорные филды, которые могли быть уже удалены в конфигах динамических полей, но при этом приходить в старых сохранённых view
        if (knownFieldsSet.has(fieldName as ORDER_CUSTOM_VISIBILITY_FIELD) || customFieldsSet.has(fieldName)) {
          hiddenOrderFields.push(fieldName as ORDER_CUSTOM_VISIBILITY_FIELD);
        }
      }
    });

    return hiddenOrderFields;
  };
}
