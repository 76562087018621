import { OrderCustomerPhone } from 'typings/dto/order';
import PhoneUtils from 'utils/Phone';

export default class PhoneMapper {
  constructor(private phoneUtils: PhoneUtils) {}

  public modelToRequestDTO = (model: Phone): string => {
    return this.phoneUtils.getRFC3966(model.raw);
  };

  public responseDTOToModel = (dto: string | OrderCustomerPhone): Phone => {
    return this.phoneUtils.getPhoneObjectFromDTO(dto);
  };
}
