import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD } from '../PATH_PARAM';

export default class CallRoutes extends FrontendRoutesBase {
  root = this.getDBPath(PATH_PARAM_DASHBOARD.call.root);

  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.call.root, PAGE_TYPE.list);
}
