import { CallResponseDTO, CreateCallRequestDTO } from 'typings/dto/call';
import Mapper from './Mapper';
import DIContainer from 'services/DIContainer';

export default class CallMapper extends Mapper<Call, CallResponseDTO, CreateCallRequestDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        callType: 'callType',
        callRecordingLink: 'callRecordingLink',
        participants: 'participants',
        callerId: null,
        orderNumber: 'orderNumber',
        orderId: 'orderId',
        success: 'success',
        duration: 'duration',
      },
      null
    );
  }

  public responseDTOToModel = (dto: CallResponseDTO): Call => {
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      phone: this.subEntitiesMappers.phone.responseDTOToModel(dto.callerId),
      participants: dto.participants.map((p) => {
        return {
          ...p,
          phone: p.phoneNumber ? this.subEntitiesMappers.phone.responseDTOToModel(p.phoneNumber) : undefined,
        };
      }),
    };
  };
}
