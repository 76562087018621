import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class MatchingConfigRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  root = this.getDBPath(PATH_PARAM_DASHBOARD.matchingConfig.root);

  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.matchingConfig.root, PAGE_TYPE.list);
}
