import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { PromotionsConfigRequestDTO, PromotionsConfigResponseDTO, UpdatePromotionsConfigResponseDTO } from 'typings/dto/promotionsConfig';
import { batch as reduxBatch } from 'react-redux';
import IPromotionsConfigActionsService from './IPromotionsConfigActionsService';

export default class PromotionsConfigActionsServiceImpl
  extends ModelActionsService<PromotionsConfig, PromotionsConfigRequestDTO, PromotionsConfigResponseDTO>
  implements IPromotionsConfigActionsService
{
  public async getPromotionsConfig(): Promise<void> {
    this.storageDispatch(this.modelStorageActions.startLoading());
    await reduxBatch(async () => {
      try {
        const response = await this.entityApiService
          .getWithCredentials<PromotionsConfigResponseDTO>(this.getModelApiRootPath(''))
          .then(this.modelMapper.responseDTOToModel);
        if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setOne(response));
        else console.warn(`Get list request ${this.modelStorageName} is outdated`);
      } catch (error) {
        if (!this.areActionsOutdated) {
          console.error(error);
          this.storageDispatch(this.modelStorageActions.setError(error));
        }
      }
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.stopLoading());
    });
  }

  public async updatePromotionsConfig(dto: PromotionsConfig): Promise<UpdatePromotionsConfigResponseDTO> {
    const response = await this.entityApiService.putWithResponse<UpdatePromotionsConfigResponseDTO>(
      this.getModelApiRootPath(dto),
      this.modelMapper.modelToRequestDTO(dto, null)
    );
    this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
    return response;
  }
}
