import { AddressWithPointDTO } from 'typings/dto/subEntity/address';

export default class AddressMapper {
  public responseDTOToModel = (dto: Partial<AddressWithPointDTO>): AddressWithPointEntity => {
    const { lat, lon } = dto;
    const geoPoint = lat && lon ? { lat, lon } : undefined;
    return {
      streetAddress: dto.addressLine1 || '',
      apartmentAddress: dto.addressLine2,
      geoPoint,
    };
  };

  public modelToRequestDTO = (entity: AddressWithPointEntity): AddressWithPointDTO => {
    return {
      addressLine1: entity.streetAddress,
      addressLine2: entity.apartmentAddress,
      lat: entity.geoPoint?.lat,
      lon: entity.geoPoint?.lon,
    };
  };
}
