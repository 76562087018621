// components
import Image from '../components/ui/images/Image';
import IFileService from 'services/file/IFileService';
import { FileIcon, ImageIcon } from 'components/ui/icons';
import FunctionUtils from './Function';

// ----------------------------------------------------------------------
// TODO Разобраться что из этого нужно

export function getFileName(fileUrl: string) {
  return fileUrl.substring(fileUrl.lastIndexOf('/') + 1).replace(/\.[^/.]+$/, '');
}

export function getFileFullName(fileUrl: string) {
  return fileUrl.split('/').pop();
}

// ---------------------------------------------------------------------------------

class FileUtils {
  public static getName = getFileName;
  public static getFullName = getFileFullName;

  public static getFormat = (fileUrl: string): FileUtils.FILE_TYPE => {
    const fileType = this.getType(fileUrl);
    return FileUtils.fileTypeMap[fileType] || FileUtils.FILE_TYPE.file;
  };

  /**
   * @param fileUrl название файла с расширением
   * @param imageUrl полная ссылка на изображение если нужно отобразить его
   */
  public static getThumb = (fileUrl: string, imageUrl?: string) => {
    let thumb;
    const format = this.getFormat(fileUrl);
    switch (format) {
      case FileUtils.FILE_TYPE.video:
        thumb = this.getIcon('format_video');
        break;
      case FileUtils.FILE_TYPE.word:
        thumb = this.getIcon('format_word');
        break;
      case FileUtils.FILE_TYPE.excel:
        thumb = this.getIcon('format_excel');
        break;
      case FileUtils.FILE_TYPE.powerpoint:
        thumb = this.getIcon('format_powerpoint');
        break;
      case FileUtils.FILE_TYPE.pdf:
        thumb = this.getIcon('format_pdf');
        break;
      case FileUtils.FILE_TYPE.image:
        thumb = imageUrl ? <Image src={imageUrl} alt={fileUrl} sx={{ width: 28, height: 28 }} /> : <ImageIcon />;
        break;
      case FileUtils.FILE_TYPE.file: {
        thumb = <FileIcon />;
        break;
      }
      default:
        FunctionUtils.exhaustiveCheck(format);
        thumb = <FileIcon />;
    }
    return thumb;
  };

  public static checkFSFilesEqual = (oldValue?: IFileService.File_ | null, newValue?: IFileService.File_ | null) => {
    return oldValue?.id === newValue?.id && oldValue?.sizeBytes === newValue?.sizeBytes;
  };

  public static checkFSFileArraysEqual = (oldValues: IFileService.File_[], newValues: IFileService.File_[]) => {
    if (oldValues.length !== newValues.length) {
      return false;
    }

    return newValues.every((value, index) => this.checkFSFilesEqual(value, oldValues[index]));
  };

  public static checkRawFilesEqual = (oldValue?: File | null, newValue?: File | null) => {
    return oldValue?.size === newValue?.size && oldValue?.name === newValue?.name && oldValue?.type === newValue?.type;
  };

  public static checkRawFileArraysEqual = (oldValue: File[], newValue: File[]) => {
    if (oldValue?.length !== newValue?.length) {
      return false;
    }

    const newValuesSorted = newValue.slice(0).sort();
    return oldValue
      .slice(0)
      .sort()
      .every((item, index) => newValuesSorted[index]?.size === item.size);
  };

  public static getFileSizeStrFormatted: (n: number) => string = this.getIntl();

  private static getIntl() {
    return Intl && Intl.NumberFormat
      ? Intl.NumberFormat('en', {
          notation: 'compact',
          style: 'unit',
          unit: 'byte',
          unitDisplay: 'narrow',
        }).format
      : (n: number) => n.toString();
  }

  private static getIcon = (name: string) => <Image src={`/icons/thumbs/${name}.svg`} alt={name} sx={{ width: 28, height: 28 }} />;

  public static getType = (fileUrl: string) => {
    return fileUrl.split('.').pop()?.toLowerCase() || '';
  };

  // Uploader utils ------------------------------------------------------------------------

  /** Добавляет превью к файлу, нужное для аплоадера */
  public static prepareNewFileForUploader = (file: File): IFileService.FileForUploaderWOData => {
    // Нужно использовать именно такую запись с Object.assign
    return Object.assign(file, {
      preview: file.type.includes('image') ? URL.createObjectURL(file) : '',
    });
  };

  /** Добавляет превью, нужное для аплоадера, при этом сохраняя данные из IFileService.File_ */
  public static prepareOldFileForUploader = (file: File, dbFileData: IFileService.File_): IFileService.FileForUploaderWithData => {
    const newFile = this.prepareNewFileForUploader(file);
    return Object.assign(newFile, {
      dbData: dbFileData,
      id: dbFileData.id,
    });
  };

  public static downloadBlob = (blob: Blob, fileName: string): void => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };
}

namespace FileUtils {
  export enum FILE_TYPE {
    file = 'file',
    image = 'image',
    video = 'video',
    pdf = 'pdf',
    powerpoint = 'powerpoint',
    word = 'word',
    excel = 'excel',
  }

  /** В класс никак не впихнуть, напрямую не использовать! Есть метод getFormat */
  export const fileTypeMap: Record<string, FileUtils.FILE_TYPE | undefined> = {
    jpg: FILE_TYPE.image,
    jpeg: FILE_TYPE.image,
    gif: FILE_TYPE.image,
    bmp: FILE_TYPE.image,
    png: FILE_TYPE.image,

    m4v: FILE_TYPE.video,
    avi: FILE_TYPE.video,
    mpg: FILE_TYPE.video,
    mp4: FILE_TYPE.video,
    webm: FILE_TYPE.video,
    mov: FILE_TYPE.video,

    doc: FILE_TYPE.word,
    docx: FILE_TYPE.word,

    xls: FILE_TYPE.excel,
    xlsx: FILE_TYPE.excel,

    pdf: FILE_TYPE.pdf,
  };
}

export default FileUtils;
