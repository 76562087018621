import {
  CreateEnterpriseRequestDTO,
  EnterpriseResponseDTO,
  UpdateEnterpriseApiSettingsRequestDTO,
  UpdateEnterpriseOrderSettingsRequestDTO,
  UpdateEnterpriseServiceCompaniesRequestDTO,
  UpdateEnterpriseTechnicianSettingsRequestDTO,
} from 'typings/dto/enterprise';
import ModelActionsService from '../ModelActionsService';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import IEnterpriseActionsService from './IEnterpriseActionsService';
import { OrderCustomFieldRequestDTO } from 'typings/dto/order';

export default class EnterpriseActionsServiceImpl
  extends ModelActionsService<Enterprise, CreateEnterpriseRequestDTO, EnterpriseResponseDTO>
  implements IEnterpriseActionsService
{
  public updateEnterpriseServiceCompanies = async ({ enterpriseId, ...dto }: UpdateEnterpriseServiceCompaniesRequestDTO) => {
    const path = `${PATH_BACKEND.enterprise.root}/${enterpriseId}/${PATH_BACKEND_PART.enterprise.ownServiceCompanies}`;
    return this.entityApiService.putWithCredentials(path, dto);
  };

  public updateApiSettings = ({ enterpriseId, ...dto }: UpdateEnterpriseApiSettingsRequestDTO) => {
    const url =
      this.getModelApiRootPath(dto) +
      '/' +
      enterpriseId +
      '/' +
      PATH_BACKEND_PART.enterprise.settings +
      '/' +
      PATH_BACKEND_PART.enterprise.webhook;
    return this.entityApiService.putWithCredentials(url, dto);
  };

  public updateOrderSettings = ({ enterpriseId, ...dto }: UpdateEnterpriseOrderSettingsRequestDTO) => {
    const url =
      this.getModelApiRootPath(dto) +
      '/' +
      enterpriseId +
      '/' +
      PATH_BACKEND_PART.enterprise.settings +
      '/' +
      PATH_BACKEND_PART.enterprise.order;
    return this.entityApiService.putWithCredentials(url, dto);
  };

  public updateTechnicianSettings = ({ enterpriseId, ...dto }: UpdateEnterpriseTechnicianSettingsRequestDTO) => {
    const url =
      this.getModelApiRootPath(dto) +
      '/' +
      enterpriseId +
      '/' +
      PATH_BACKEND_PART.enterprise.settings +
      '/' +
      PATH_BACKEND_PART.user.technician;
    return this.entityApiService.putWithCredentials(url, dto);
  };

  public createOrderCustomField = (enterpriseId: string, dto: OrderCustomFieldRequestDTO) => {
    const url =
      this.getModelApiRootPath(dto) +
      '/' +
      enterpriseId +
      '/' +
      PATH_BACKEND_PART.enterprise.settings +
      '/' +
      PATH_BACKEND_PART.enterprise.order +
      '/custom-fields';
    return this.entityApiService.postWithCredentials<{ id: string }>(url, dto).then((res) => res.id);
  };

  public updateOrderCustomField = (enterpriseId: string, dto: OrderCustomFieldRequestDTO) => {
    const url =
      this.getModelApiRootPath(dto) +
      '/' +
      enterpriseId +
      '/' +
      PATH_BACKEND_PART.enterprise.settings +
      '/' +
      PATH_BACKEND_PART.enterprise.order +
      '/custom-fields/' +
      dto.id;
    return this.entityApiService.putWithCredentials(url, dto);
  };

  public deleteOrderCustomField = (enterpriseId: string, fieldId: string) => {
    const url =
      this.getModelApiRootPath(null) +
      '/' +
      enterpriseId +
      '/' +
      PATH_BACKEND_PART.enterprise.settings +
      '/' +
      PATH_BACKEND_PART.enterprise.order +
      '/custom-fields/' +
      fieldId;
    return this.entityApiService.deleteWithCredentials(url);
  };

  public getValuesFromOrderForFilter = () => {
    const url = PATH_BACKEND.enterprise.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };
}
