import React from 'react';
import { SxProps } from '@mui/system';
import { Stack, Box, StackProps } from '@mui/material';
import Fieldset from 'components/ui/forms/utilComponents/Fieldset';
import { Form, FormikProvider, FormikContextType } from 'formik';
import { EDIT_FORM_WIDTH } from 'configs/layout';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from 'hooks/useMediaQuery';
import { Theme } from '@mui/material/styles';

export type FormProviderProps = Omit<StackProps, 'onSubmit'> & {
  disabled?: boolean; // На удаление?
  children: React.ReactNode;
  formState: FormikContextType<any>;
  fullWidth?: boolean;
  submitOnEnter?: boolean;
  /** Пришлось отделить стили внешнего контейнера формы от самой формы */
  containerSx?: SxProps<Theme>;
};

const isGapSupported = window.CSS?.supports?.('(display: flex) and (gap: 3px)');

// TODO тут возможно устарела работа с шириной вложенных компонентов
export default function FormProvider({
  disabled,
  children,
  formState,
  fullWidth,
  direction,
  justifyContent,
  alignItems,
  sx,
  containerSx,
  submitOnEnter,
  ...rest
}: FormProviderProps) {
  const isRow = direction === 'row';
  direction = isRow ? 'row' : 'column';
  disabled = disabled !== undefined ? disabled : formState.isSubmitting;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onKeyDownHandler = submitOnEnter
    ? (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.key === 'Enter') formState.handleSubmit();
      }
    : undefined;

  return (
    <Box sx={{ width: '100%', ...containerSx }}>
      <FormikProvider value={formState}>
        <Form noValidate autoComplete="nope" onSubmit={formState.handleSubmit} onKeyDown={onKeyDownHandler}>
          <Fieldset disabled={disabled}>
            <Stack
              direction={direction ? { xs: 'column', md: direction } : 'column'}
              sx={{
                gap: isGapSupported ? 3 : undefined,
                maxWidth: { md: isRow || fullWidth ? '100%' : `${EDIT_FORM_WIDTH}px` },

                ...sx,
              }}
              justifyContent={justifyContent || isMobile ? 'stretch' : 'flex-end'}
              alignItems={direction === 'row' ? alignItems || 'center' : alignItems}
              flexWrap="wrap"
              {...rest}
            >
              {children}
            </Stack>
          </Fieldset>
        </Form>
      </FormikProvider>
    </Box>
  );
}
