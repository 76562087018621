import { CreateMatchingConfigRequestDTO, MatchingConfigResponseDTO } from 'typings/dto/matchingConfig';
import ModelActionsService from '../ModelActionsService';
import IMatchingConfigService from './IMatchingConfigService';

export default class MatchingConfigServiceImpl
  extends ModelActionsService<MatchingConfig, CreateMatchingConfigRequestDTO, MatchingConfigResponseDTO>
  implements IMatchingConfigService
{
  public async updateMatchingConfig(dto: CreateMatchingConfigRequestDTO, customPath?: string): Promise<void> {
    await this.entityApiService.patchWithCredentials(customPath || this.getModelApiRootPath(dto), dto, false);
  }
}
