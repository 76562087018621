export enum PRICE_TYPE {
  client = 'CLIENT',
  technician = 'TECHNICIAN',
  serviceProvider = 'SERVICE_PROVIDER',
  platformOperator = 'PLATFORM_OPERATOR',
}

export enum PRICE_VALUE_TYPE {
  percent = 'PRODUCT_PRICE_PERCENT',
  amount = 'AMOUNT',
}
