export enum ORDER_DEFAULT_TYPE {
  default = 'default',
  complaint = 'reclamation',
}

export enum ORDER_TYPE_ICON_NAME {
  defaultOrderType = 'DefaultOrderType',
  warrantyOrderType = 'WarrantyOrderType',
  measureOrderType = 'MeasureOrderType',
  reclamationOrderType = 'ReclamationOrderType',
}
