import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import FormUtils from 'utils/Form';
import useLocales from 'hooks/useLocales';
import { PROMOTION_PREDEFINED_TYPE } from 'typings/models/promotionsConfig.enum';
import PopupContent from 'components/ui/popups/PopupContent';
import FormProvider from 'components/ui/forms/FormProvider';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import AutocompleteMemorized from 'components/ui/forms/AutocompleteMemorized';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import SelectSemiSimpleMemorized from 'components/ui/forms/SelectSemiSimpleMemorized';
import FunctionUtils from 'utils/Function';
import AutocompleteCheckboxesMemorized from 'components/ui/forms/AutocompleteCheckboxesMemorized';
// Переопределены стили MUI для инпутов, почему то компоненту TextFieldMemorized можно задать ширину через fullWidth или sx,
// А AutocompleteMemorized, SelectSemiSimpleMemorized только если задать class. см export default function TextField(theme: Theme)
import { INPUT_FULL_WIDTH_CLASS_NAME } from 'configs/layout';

const PredefinedDictionaryVariableEditPopup = ({
  isOpened,
  closeHandler,
  formsStatesMapRef,
  formId,
  formChangedCallback,
  rerenderPageCallback,
  dictionaryMap,
}: {
  isOpened: boolean;
  closeHandler: VoidFunction;
  formId?: string;
  formsStatesMapRef: React.MutableRefObject<FormUtils.ChildFormsMap>;
  rerenderPageCallback: VoidFunction;
  formChangedCallback: VoidFunction;
  dictionaryMap: Record<string, Dictionary>;
}) => {
  const { translate } = useLocales();
  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(fieldIsRequiredText),
    type: Yup.string().required(fieldIsRequiredText),
    values: Yup.array().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) =>
        Boolean(testContext.parent.type === PROMOTION_PREDEFINED_TYPE.dictionaryListValue ? thisValue : true),
    }),
    value: Yup.mixed().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) =>
        Boolean(testContext.parent.type === PROMOTION_PREDEFINED_TYPE.dictionarySingleValue ? thisValue : true),
    }),
    dictionaryId: Yup.string().required(),
  });

  const initialValues = useMemo(() => {
    if (formId === undefined) {
      return {
        name: '',
        type: PROMOTION_PREDEFINED_TYPE.dictionarySingleValue,
        expression: '',
        dictionaryId: '',
        values: [],
        value: null,
      };
    }
    const values = formsStatesMapRef.current.get(formId)?.values;
    return {
      name: values.name,
      type: values.type,
      expression: values.expression,
      dictionaryId: values.dictionaryId,
      values: values.values,
      value: values.value,
    };
  }, [formId]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values): Promise<void> => {
      if (!values.type || !values.dictionaryId) {
        throw new Error('Data is missing');
      }
      let existingForm = null;
      if (formId) {
        existingForm = formsStatesMapRef.current.get(formId);
        if (!existingForm) {
          throw new Error('Unexpected form state');
        }
        existingForm.setFieldValue('type', values.type);
        existingForm.setFieldValue('name', values.name);
        const type = values.type as PROMOTION_PREDEFINED_TYPE;
        switch (type) {
          case PROMOTION_PREDEFINED_TYPE.dictionaryListValue: {
            if (!values.values) {
              throw new Error('Data is missing');
            }
            existingForm.setFieldValue('dictionaryId', values.dictionaryId);
            existingForm.setFieldValue('values', values.values);
            break;
          }
          case PROMOTION_PREDEFINED_TYPE.dictionarySingleValue: {
            if (!values.value) {
              throw new Error('Data is missing');
            }
            existingForm.setFieldValue('dictionaryId', values.dictionaryId);
            existingForm.setFieldValue('value', values.value);
            break;
          }
          case PROMOTION_PREDEFINED_TYPE.expression: {
            throw new Error('Wrong variable type');
          }
          default: {
            FunctionUtils.exhaustiveCheck(type);
          }
        }
        closeHandler();
        rerenderPageCallback();
      } else {
        let model: PromotionsPredefined.PromotionsPredefinedBase = {
          type: values.type,
          name: values.name,
        };

        switch (model.type) {
          case PROMOTION_PREDEFINED_TYPE.dictionaryListValue: {
            if (!values.values) {
              throw new Error('Data is missing');
            }
            break;
          }
          case PROMOTION_PREDEFINED_TYPE.dictionarySingleValue: {
            if (!values.value) {
              throw new Error('Data is missing');
            }
            break;
          }
          case PROMOTION_PREDEFINED_TYPE.expression: {
            throw new Error('Wrong variable type');
          }
          default: {
            FunctionUtils.exhaustiveCheck(model.type);
          }
        }
        const newId = Math.random().toString(); //Не использую FormUtils, потому что нужен formId.
        formsStatesMapRef.current.set(newId, formState);
        rerenderPageCallback();
      }

      formChangedCallback();
      closeHandler();
    },
  });
  const dictionaryEntriesOptions = useMemo(() => {
    return dictionaryMap[formState.values.dictionaryId]?.entries || [];
  }, [formState.values.dictionaryId]);

  return (
    <PopupContent title={translate('pages.promotionsVariablesEdit.addDictionaryVariable')} isOpened={isOpened} closeHandler={closeHandler}>
      <FormProvider formState={formState} fullWidth>
        <TextFieldMemorized formState={formState} fieldName="name" label={translate('pages.promotionsVariablesEdit.setName')} fullWidth />

        <SelectSemiSimpleMemorized
          formState={formState}
          fieldName="dictionaryId"
          label={translate('pages.promotionsVariablesEdit.dictionaryLabel')}
          options={Array.from(Object.values(dictionaryMap))}
          onChange={(event) => {
            formState.setFieldValue('dictionaryId', event.target.value);
            formState.setFieldValue('value', null);
            formState.setFieldValue('values', []);
            formChangedCallback();
          }}
          className={INPUT_FULL_WIDTH_CLASS_NAME}
        />
        <RadioGroup value={formState.values.type} onChange={(event) => formState.setFieldValue('type', event.target.value)}>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              value={PROMOTION_PREDEFINED_TYPE.dictionarySingleValue}
              label={translate('entities.promotions.predefined.predefinedType.dictionarySingleValue')}
              control={<Radio />}
            />
            <FormControlLabel
              value={PROMOTION_PREDEFINED_TYPE.dictionaryListValue}
              label={translate('entities.promotions.predefined.predefinedType.dictionaryListValue')}
              control={<Radio />}
            />
          </Stack>
        </RadioGroup>

        {formState.values.type === PROMOTION_PREDEFINED_TYPE.dictionarySingleValue && (
          <AutocompleteMemorized
            formState={formState}
            fieldName="value"
            label={translate('pages.promotionsVariablesEdit.value')}
            options={dictionaryEntriesOptions}
            key={formState.values.dictionaryId}
            className={INPUT_FULL_WIDTH_CLASS_NAME}
          />
        )}
        {formState.values.type === PROMOTION_PREDEFINED_TYPE.dictionaryListValue && (
          <AutocompleteCheckboxesMemorized
            formState={formState}
            fieldName="values"
            label={translate('pages.promotionsVariablesEdit.values')}
            options={dictionaryEntriesOptions}
            key={formState.values.dictionaryId}
            className={INPUT_FULL_WIDTH_CLASS_NAME}
          />
        )}
        <ConfirmButtonGroup isLoading={formState.isSubmitting} cancelHandler={closeHandler} />
      </FormProvider>
    </PopupContent>
  );
};

export default React.memo(
  PredefinedDictionaryVariableEditPopup,
  (pp, np) => pp.formId === np.formId && pp.isOpened === np.isOpened && pp.dictionaryMap === np.dictionaryMap
);
