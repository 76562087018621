import AuthRoutes from './dashboard/Auth';
import ClientOrderRoutes from './client/ClientOrder';
import ServiceProviderSettingsRoutes from './dashboard/ServiceProviderSettings';
import InvoiceProviderRoutes from './dashboard/InvoiceProvider';
import AdministrationRoutes from './dashboard/Administration';
import MeRoutes from './dashboard/Me';
import CallRoutes from './dashboard/Call';
import EnterpriseSettingsRoutes from './dashboard/EnterpriseSettings';
import OrderRoutes from './dashboard/Order';
import WidgetRoutes from './dashboard/widget';
import { PATH_PARAM_CLIENT, PATH_PARAM_DASHBOARD } from './PATH_PARAM';
import ServiceCatalogRoutes from './dashboard/Service';
import PriceClientRoutes from './dashboard/PriceClient';
import PriceTechnicianRoutes from './dashboard/PriceTechnician';
import ContractSPRoutes from './dashboard/ContractSP';
import PriceServiceProviderRoutes from './dashboard/PriceServiceProvider';
import FundsTransactionRoutes from './dashboard/FundsTransaction';
import SuperadminRoutes from './dashboard/Superadmin';
import EnterpriseRoutes from './dashboard/Enterprise';
import ServiceProviderRoutes from './dashboard/ServiceProvider';
import PricePlatformOperatorRoutes from './dashboard/PricePlatformOperator';
import RecommendedClientPriceRoutes from './dashboard/RecommendedClientPrice';
import ContractPORoutes from './dashboard/ContractPO';
import InvoicePORoutes from './dashboard/InvoicePO';
import PromotionsConfigRoutes from './dashboard/PromotionsConfig';
import PlatformOperatorRoutes from './dashboard/PlatformOperator';
import RecommendationsConfigRoutes from 'services/routing/dashboard/RecommendationsConfig';
import MatchingConfigRoutes from './dashboard/MatchingConfig';

/**
 * Лучше импортировать основной сервис, а не эту его часть
 * @see RoutingService
 * @todo убрать экспорт (это в очень отдалённом будущем, т.к. импорт этого сервиса повсюду)
 */
export class RoutingServiceDashboard {
  public static root = '/' + PATH_PARAM_DASHBOARD.root;
  public static order = new OrderRoutes();
  public static invoiceProvider = new InvoiceProviderRoutes();
  public static invoicePO = new InvoicePORoutes();
  public static service = new ServiceCatalogRoutes();
  public static administration = new AdministrationRoutes();
  public static call = new CallRoutes();
  public static serviceProviderSettings = new ServiceProviderSettingsRoutes();
  public static serviceProvider = new ServiceProviderRoutes();
  public static enterpriseSettings = new EnterpriseSettingsRoutes();
  public static enterprise = new EnterpriseRoutes();
  public static platformOperator = new PlatformOperatorRoutes();
  public static me = new MeRoutes();
  public static widget = new WidgetRoutes();
  public static priceClient = new PriceClientRoutes();
  public static priceTechnician = new PriceTechnicianRoutes();
  public static priceServiceProvider = new PriceServiceProviderRoutes();
  public static pricePlatformOperator = new PricePlatformOperatorRoutes();
  public static recommendedClientPrice = new RecommendedClientPriceRoutes();
  public static contractSP = new ContractSPRoutes();
  public static contractPO = new ContractPORoutes();
  public static fundsTransaction = new FundsTransactionRoutes();
  public static superadmin = new SuperadminRoutes();
  public static promotionsConfig = new PromotionsConfigRoutes();
  public static recommendationsConfig = new RecommendationsConfigRoutes();
  public static matchingConfig = new MatchingConfigRoutes();
}

/**
 * Сервис генерации адресов и хлебных крошек фронтенда.
 * Не имеет зависимостей, поэтому отсутствует в DI, сделан на static методах, да и вообще больше похож на утилиту
 */
class RoutingService {
  public static auth = new AuthRoutes();
  public static client = {
    root: '/' + PATH_PARAM_CLIENT.root,
    order: new ClientOrderRoutes(),
  };
  public static dashboard = RoutingServiceDashboard;
}

export default RoutingService;
