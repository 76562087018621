export enum CONTRACT_STATUS {
  new = 'NEW',
  suspended = 'SUSPENDED',
  // CANCELLED = 'CANCELLED',
  active = 'ACTIVE',
}

export enum CONTRACT_AGREEMENT_TYPE {
  contractor = 'CONTRACTOR',
  agent = 'AGENT',
}
